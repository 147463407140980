import telemetrySchema from '@amzn/pxt-telemetry-schema-registry/dist/jsonSchema/arn:pxt:telemetry:event:clarity:common:2.0.0.json';
import analyticsStorage from '@clarity-website/common/analyticsStorage';
import { Telemetry } from '@clarity-website/common/telemetry/Telemetry';
import {
  CommonTestSchema,
  requiredProperties as localSchemaRequiredProperties,
} from '@clarity-website/common/telemetry/localEventOverrides';

export const TelemetrySchema = Object.freeze({
  common: 'arn:pxt:telemetry:event:clarity:common:2.0.0',
  employeeCore: 'arn:pxt:telemetry:actor:core:employee:1.0.0',
  localTestSchema: 'arn:pxt:telemetry:event:clarity:common:2.0.0',
});

export type TelemetrySchema =
  (typeof TelemetrySchema)[keyof typeof TelemetrySchema];

export const setPxtTelemetryContext = (employeeLogin: string): boolean => {
  try {
    Telemetry.client.describeActor({
      resourceName: TelemetrySchema.employeeCore,
      payload: {
        login: employeeLogin,
      },
    });
    console.log(
      'Successfully set PXT Telemetry context for employee:',
      employeeLogin,
    );
    return true;
  } catch (error) {
    console.error('Failed to set PXT Telemetry context:', error);
    return false;
  }
};

export const sendImperativeEvent = (
  payload: any,
  scopePath: string[] = [''],
) => {
  const { name: eventType, ...rest } = payload;
  const modifiedPayload = modifyEventPayloadToMatchSchema({
    ...rest,
    eventType,
  });
  if (modifiedPayload) {
    Telemetry.client.sendImperativeEvents({
      event: {
        resourceName: Telemetry.imperativeSchema,
        payload: modifiedPayload,
      },
      metaPayload: {
        scopePath,
      },
    });
  }
};

export const describeClickEvent = (payload: any) => {
  const modifiedPayload = modifyEventPayloadToMatchSchema({
    ...payload,
    ...analyticsStorage.getGlobalAttributes(),
  });

  if (modifiedPayload) {
    const description = Telemetry.client.describeEvents({
      triggerResourceNames: ['arn:pxt:telemetry:trigger:core:click:*'],
      events: [
        {
          resourceName: Telemetry.imperativeSchema,
          payload: modifiedPayload,
        },
      ],
    });
    return description.eventAttributeValue;
  }
};

/**
 * Won't need this function once we migrate fully to PXT telemetry to record user engagement.
 * Pinpoint analytics requires a nested object but the schema we've registered with pxt telemetry is one dimensional.
 * We need this method to flatten the analytics event so it's compatible with the registered schema.
 */
const flattenPayload = (payload: any) => {
  const flattened: any = {};
  Object.keys(payload).forEach((key) => {
    const value = payload[key];
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenPayload(value));
    } else {
      if (value !== null) flattened[key] = value;
    }
  });

  return flattened;
};

// Properties that are included in a amplify analytical event that have to be redacted from a pxt telemetry event
const fieldsToRedactFromTelemetry = [
  'employeeLogin',
  'employeeFirstName',
  'employeeLastName',
  'employeeCountry',
  'employeeSite',
  'employeeGroups',
  'employeeAdGroups',
  'employeePosixGroups',
  'employeeJobTitle',
  'employeeJobLevel',
  'screen',
  'viewport',
  'selectedOptions',
];
const redactFieldsFromTelemetry = (payload: any) => {
  fieldsToRedactFromTelemetry.forEach((prop) => {
    delete payload[prop];
  });
};

// These properties were registered wrongly as strings in the pxt registry schema. Need to fix them in the next schema version update.
const fieldsThatWereRegisteredAsStrings: string[] = [];
const correctMismatchedDataTypes = (payload: any) => {
  fieldsThatWereRegisteredAsStrings?.forEach((prop) => {
    if (typeof payload[prop] !== 'undefined') {
      payload[prop] = `${payload[prop]}`;
    }
  });
};

const getRegisteredSchemaProperties = () => {
  if (
    Telemetry.imperativeSchema !== TelemetrySchema.localTestSchema ||
    process.env.NODE_ENV === 'production'
  ) {
    try {
      return {
        allProperties: telemetrySchema.properties,
        requiredPropertiesKeys: telemetrySchema.required,
      };
    } catch (error) {
      console.error(
        'Failed to consume telemetry schema json file. Defaulting to local schema',
        error,
      );
    }
  }

  return {
    allProperties: new CommonTestSchema(),
    requiredPropertiesKeys: localSchemaRequiredProperties,
  };
};

const includeAdditionalPropertiesInPayload = (payload: any) => {
  const { allProperties } = getRegisteredSchemaProperties();
  const additionalProperties: any = {};
  const propertyKeys = Object.keys(allProperties);
  Object.keys(payload).forEach((prop: any) => {
    if (!propertyKeys.includes(prop)) {
      additionalProperties[prop] = payload[prop];
      delete payload[prop];
    }
  });

  return Object.keys(additionalProperties)?.length !== 0
    ? { ...payload, additionalData: JSON.stringify(additionalProperties) }
    : payload;
};

const hasRequiredProperties = (payload: any) => {
  const { requiredPropertiesKeys } = getRegisteredSchemaProperties();
  const missingFields = requiredPropertiesKeys.filter(
    // eslint-disable-next-line no-prototype-builtins
    (prop: string) => !payload.hasOwnProperty(prop),
  );
  if (missingFields.length > 0) {
    console.error(
      `Missing required fields from payload: ${missingFields.join(', ')}`,
    );
  }
  return missingFields.length === 0;
};

const hasValidPropertyDataTypes = (payload: any) => {
  const { allProperties } = getRegisteredSchemaProperties();
  const mismatchedDataTypes = Object.keys(allProperties).filter((prop) => {
    const schemaType = allProperties[prop]?.type ?? typeof allProperties[prop];
    const payloadType = typeof payload[prop];
    return (
      schemaType !== 'undefined' &&
      payloadType !== schemaType &&
      payloadType !== 'undefined'
    );
  });
  if (mismatchedDataTypes.length > 0) {
    console.error(
      `Data type mismatch for properties: ${mismatchedDataTypes.join(', ')}`,
    );
  }
  return mismatchedDataTypes.length === 0;
};

export const modifyEventPayloadToMatchSchema = (payload: any) => {
  if (!payload) return null;
  const flattenedPayload = flattenPayload(payload);
  redactFieldsFromTelemetry(flattenedPayload);
  correctMismatchedDataTypes(flattenedPayload);
  // Will uncomment once a new schema version is registered that contains the additionalData field.

  // All fields that are included in the payload but not in the registered schema will be included in the additionalData field.
  const modifiedPayload =
    includeAdditionalPropertiesInPayload(flattenedPayload);

  if (
    !(
      hasRequiredProperties(modifiedPayload) &&
      hasValidPropertyDataTypes(modifiedPayload)
    )
  ) {
    console.error(
      `The PXT telemetry event payload does not comply with the schema,
    payload: ${JSON.stringify(modifiedPayload)}`,
    );
    if (process.env.NODE_ENV !== 'production') return null;
  }
  return modifiedPayload;
};
