import {
  PeriodType,
  TimespanComparison,
  TimespanComparisonType,
} from '@amzn/claritygqllambda';
import { DomainConfig } from '@amzn/pi-clarity-common/config/domain-configs';
import { timeUnitDisplayValues } from '@amzn/pi-clarity-common/timespan/constants';
import {
  buildRollingTimespanFromId,
  generateRollingTimespanSchema,
  timespanLabelToRollingTimespanData,
} from '@amzn/pi-clarity-common/timespan/rollingDateUtils';
import {
  ComparisonsState,
  TimeUnit,
} from '@amzn/pi-clarity-common/timespan/types';
import {
  buildSpecificTimespanFromId,
  generateSpecificTimespanSchema,
  timespanLabelToSpecificTimespanData,
} from '@clarity-website/reports/edit/timespan/specificDateUtils';
import pluralize, { singular } from 'pluralize';
import { intersection } from 'ramda';

export const isSpecificDate = (timespanId: string) => {
  // Matching timespanId containing epoch, e.g: 'Cron_Sequence:...identity^1688169600...'
  const regex = new RegExp(/\d{10,}/gm); // Matching 10..unlimited times numbers in the range 0-9
  return regex.test(timespanId);
};

export const buildTimespanFromId = (id: string, domain: string) => {
  return isSpecificDate(id)
    ? buildSpecificTimespanFromId(id, domain)
    : buildRollingTimespanFromId(id, domain);
};

export const initComparisonsState = (
  comparisons: PeriodType[],
  preselectedComparisons: TimespanComparison[] = [],
) => {
  const comparisonState = comparisons.reduce((acc: ComparisonsState, next) => {
    acc[next.toString()] = { isActive: false };
    return acc;
  }, {});
  preselectedComparisons.forEach((timespanComparison) => {
    comparisonState[timespanComparison.comparison.toString()] = {
      isActive: true,
      comparisonType: timespanComparison.comparisonType,
    };
  });
  return comparisonState;
};

export const buildTimespanComparisons = (
  comparisonsState: ComparisonsState,
) => {
  return Object.keys(comparisonsState).reduce(
    (acc: TimespanComparison[], next) => {
      const state = comparisonsState[next];
      if (state.isActive && state.comparisonType) {
        acc.push({
          comparison: next as PeriodType,
          comparisonType:
            state.comparisonType.toLocaleLowerCase() as TimespanComparisonType,
        });
      }
      return acc;
    },
    [],
  );
};

export const getComparisonTypeDisplayValue = (
  comparisonType: string,
  timeUnit: TimeUnit,
) => {
  const timeUnitDisplayValue = timeUnitDisplayValues[timeUnit];
  if (comparisonType.toLocaleLowerCase() === TimespanComparisonType.All) {
    return `${comparisonType} ${pluralize(
      timeUnitDisplayValue.toLocaleLowerCase(),
    )}`;
  }
  if (comparisonType.toLocaleLowerCase() === TimespanComparisonType.Latest) {
    return `Most recent ${singular(timeUnitDisplayValue.toLocaleLowerCase())}`;
  }
  return comparisonType;
};

// Generates an array containing unique PeriodTypes from the provided array of timespan ids
export const getUniquePeriodTypes = (timespanIds: string[], domain: string) => {
  return [
    ...new Set(
      timespanIds.map((id) => buildTimespanFromId(id, domain).periodType),
    ),
  ];
};

// Returns the first element in `expectedPeriodTypes` unless any element from `availablePeriodTypes` is found in `expectedPeriodTypes`
export const getNeededPeriodType = (
  expectedPeriodTypes: PeriodType[],
  availablePeriodTypes: PeriodType[],
): PeriodType | undefined => {
  return intersection(expectedPeriodTypes, availablePeriodTypes).length === 0
    ? expectedPeriodTypes[0]
    : undefined;
};

export const timespanLabelToTimespanSchema = (
  label: string,
  domain: string,
  domainConfig: DomainConfig,
) => {
  const specificTimespanData = timespanLabelToSpecificTimespanData(label);
  if (specificTimespanData.start && specificTimespanData.end) {
    return generateSpecificTimespanSchema(
      {
        start: specificTimespanData.start,
        end: specificTimespanData.end,
        type: specificTimespanData.timeUnit,
      },
      domain,
      domainConfig,
      specificTimespanData.comparisons,
    );
  }
  const rollingTimespanData = timespanLabelToRollingTimespanData(label);
  return generateRollingTimespanSchema(
    rollingTimespanData,
    domain,
    domainConfig,
  );
};
